import React from "react"
import { FaInfoCircle } from "react-icons/fa"

const Packages = () => (
  <div id="packages">
    <div className="row">
      <div className="form-check col-lg col-md-6">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="attendance" value="Public Sector Seat" style={{ display: `none` }} />
          <div className="radio-highlight" data-toggle="collapse" data-target="#collapsePublicSeat" style={{
            padding: `2rem 0`
            }}>Public Sector Seat<br /><span style={{ fontSize: `14px` }}>&pound;325 + VAT</span></div>
        </label>
      </div>
      <div className="form-check col-lg col-md-6">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="attendance" value="Private Sector Seat" style={{ display: `none` }}  />
          <div className="radio-highlight" data-toggle="collapse" data-target="#collapsePrivateSeat" style={{
            padding: `2rem 0`
            }}>Private Sector Seat<br /><span style={{ fontSize: `14px` }}>&pound;495 + VAT</span></div>
        </label>
      </div>
      <div className="form-check col-lg mt-md-4 mt-lg-0">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="attendance" value="Table Package" style={{ display: `none` }} />
          <div className="radio-highlight" data-toggle="collapse" data-target="#collapseTable" style={{
            padding: `2rem`
            }}>Table Package<br /><span style={{ fontSize: `14px` }}>From &pound;1500 + VAT</span></div>
        </label>
      </div>
    </div>

    <br />

    <div className="row collapse" id="collapsePublicSeat" data-parent="#packages">
      <p className="container mb-4"><strong>How Many Seats Do You Need?</strong></p>
      <div className="form-check col-lg">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="publicSeatType" value="1 Public Sector Seat" style={{ display: `none` }} />
          <div className="radio-highlight" style={{
            padding: `1rem 0`
            }}>1 Seat<br /><span style={{ fontSize: `14px` }}>&pound;325 + VAT</span></div>
        </label>
      </div>
      <div className="form-check col-lg">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="publicSeatType" value="2 Public Sector Seats" defaultChecked="true" style={{ display: `none` }}  />
          <div className="radio-highlight" style={{
            padding: `1rem 0`
            }}>2 Seats<br /><span style={{ fontSize: `14px` }}>&pound;650 + VAT</span></div>
        </label>
      </div>
      <div className="form-check col-lg">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="publicSeatType" value="3 Public Sector Seats" style={{ display: `none` }} />
          <div className="radio-highlight" style={{
            padding: `1rem`
            }}>3 Seats<br /><span style={{ fontSize: `14px` }}>&pound;975 + VAT</span></div>
        </label>
      </div>
      <div className="form-check col-lg">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="publicSeatType" value="4 Public Sector Seats" style={{ display: `none` }} />
          <div className="radio-highlight" style={{
            padding: `1rem 0`
            }}>4 Seats<br /><span style={{ fontSize: `14px` }}>&pound;1300 + VAT</span></div>
        </label>
      </div>
    </div>

    <div className="row collapse" id="collapsePrivateSeat" data-parent="#packages">
      <p className="container mb-4"><strong>How Many Seats Do You Need?</strong></p>
      <div className="form-check col-lg">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="privateSeatType" value="1 Private Sector Seat" style={{ display: `none` }} />
          <div className="radio-highlight" style={{
            padding: `1rem 0`
            }}>1 Seat<br /><span style={{ fontSize: `14px` }}>&pound;495 + VAT</span></div>
        </label>
      </div>
      <div className="form-check col-lg">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="privateSeatType" value="2 Private Sector Seats" defaultChecked="true" style={{ display: `none` }}  />
          <div className="radio-highlight" style={{
            padding: `1rem 0`
            }}>2 Seats<br /><span style={{ fontSize: `14px` }}>&pound;990 + VAT</span></div>
        </label>
      </div>
      <div className="form-check col-lg">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="privateSeatType" value="3 Private Sector Seats" style={{ display: `none` }} />
          <div className="radio-highlight" style={{
            padding: `1rem`
            }}>3 Seats<br /><span style={{ fontSize: `14px` }}>&pound;1485 + VAT</span></div>
        </label>
      </div>
      <div className="form-check col-lg">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="privateSeatType" value="4 Private Sector Seats" style={{ display: `none` }} />
          <div className="radio-highlight" style={{
            padding: `1rem 0`
            }}>4 Seats<br /><span style={{ fontSize: `14px` }}>&pound;1980 + VAT</span></div>
        </label>
      </div>
    </div>

    <div className="row collapse" id="collapseTable" data-parent="#packages">
      <p className="container mb-4"><strong>Select Your Table Requirements:</strong></p>
      <div className="form-check col-lg">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="tableType" value="Public Sector Half Table" style={{ display: `none` }} />
          <div className="radio-highlight" style={{
            padding: `1rem 0`
            }}>Half Table<br /><span style={{ fontSize: `14px` }}>Public Sector <span data-toggle="tooltip" data-placement="top" title="All Public Sector"><FaInfoCircle /></span><br />&pound;1500 + VAT</span></div>
        </label>
      </div>
      <div className="form-check col-lg">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="tableType" value="Public Sector Full Table" style={{ display: `none` }}  />
          <div className="radio-highlight" style={{
            padding: `1rem 0`
            }}>Full Table<br /><span style={{ fontSize: `14px` }}>Public Sector <span data-toggle="tooltip" data-placement="top" title="All Public Sector"><FaInfoCircle /></span><br />&pound;2750 + VAT</span></div>
        </label>
      </div>
      <div className="form-check col-lg-4">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="tableType" value="Combined Public and Private Full Table" defaultChecked="true" style={{ display: `none` }} />
          <div className="radio-highlight" style={{
            padding: `1rem 0`
            }}>Combined Sector Table<br /><span style={{ fontSize: `14px` }}>5 Seats Public, 5 Seats Private<br />&pound;3250 + VAT</span></div>
        </label>
      </div>
      <div className="form-check col-lg">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="tableType" value="Private Sector Half Table" style={{ display: `none` }}  />
          <div className="radio-highlight" style={{
            padding: `1rem 0`
            }}>Half Table<br /><span style={{ fontSize: `14px` }}>Private Sector <span data-toggle="tooltip" data-placement="top" title="All Private Sector"><FaInfoCircle /></span><br />&pound;2350 + VAT</span></div>
        </label>
      </div>
      <div className="form-check col-lg">
        <label className="form-check-label" style={{
          border: `1px solid #fff`,
          width: `100%`,
          textAlign: `center`,
        }}>
          <input className="form-check-input radio-custom" type="radio" name="tableType" value="Private Sector Full Table" style={{ display: `none` }} />
          <div className="radio-highlight" style={{
            padding: `1rem`
            }}>Full Table<br /><span style={{ fontSize: `14px` }}>Private Sector <span data-toggle="tooltip" data-placement="top" title="All Private Sector"><FaInfoCircle /></span><br />&pound;4450 + VAT</span></div>
        </label>
      </div>
    </div>
    
  </div>
)

export default Packages
